<template>
  <div class="trademarkMonitoring">
    <div style="height: 90px"/>
    <div class="danTitle">
      <span @click="skip('/')">首页</span>
      >
      <span @click="skip('/PQ')">商标查询</span>
      >
      <span @click="skip('/TM')">商标监控</span>
    </div>

    <section class="search-option">
      <img class="zhihui" src="../../assets/images/informationupload/zhihui.png" alt="">
      <span class="search_title">监控商标</span>
      <el-input placeholder="监控商标" v-model="monitoringTrademark" class="_one"/>

      <span style="margin-left: 60px" class="search_title">开始时间</span>
      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="start_date_1" type="date" placeholder="选择时间"/>
      <i style="margin-right: 5px" class="el-icon-minus"/>
      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="start_date_2" type="date" placeholder="选择时间"/>

      <span style="margin-left: 60px" class="search_title">结束日期</span>
      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="end_date_1" type="date" placeholder="选择时间"/>
      <i style="margin-right: 5px" class="el-icon-minus"/>
      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="end_date_2" type="date" placeholder="选择时间"/>

      <el-button type="primary" style="margin-left: 80px;width: 85px;border-radius: 10px;text-align: center" @click="getList">查询</el-button>

      <el-button style="width: 85px;border-radius: 10px;text-align: center" @click="resetCondtion">重置</el-button>

      <el-button style="border-radius: 10px;text-align: center" type="primary" plain @click="dueMonth()">本月到期</el-button>
    </section>

    <div class="monitoring_table">
      <vxe-grid
        header-row-class-name="vxe_grid_header"
        border="inner"
        height="720px"
        :highlight-current-row="true"
        :highlight-hover-row="true"
        :highlight-current-column="true"
        align="center"
        header-align="center"
        ref='xGrid'
        :cell-class-name="cellClassName"
        :row-style="rowStyle"
        v-bind="gridOptions"
        @clear-filter="filterChange"
        @filter-change="filterChange"
        @sort-change="filterChange"
      >

        <!--将表单放在工具栏中-->
        <template #toolbar_buttons>
          <div class="monitoring_two">
            <div>
              <el-tooltip class="item" effect="light" content="监控中的商标，一键续费，为您延长1年监控时间。" placement="top">
                <el-button style="padding: 9px 15px;margin-left: 30px" type="primary" round @click="renewal()">一键续费</el-button>
              </el-tooltip>

              <el-tooltip class="item" effect="light" content="已终止的商标，一键启用，以同样监控条件，为您重新开启监控。" placement="top">
                <el-button style="padding: 9px 15px;margin-left: 30px" type="primary" round @click="enable()">一键启用</el-button>
              </el-tooltip>

              <el-tooltip class="item" effect="light" content="一个商标监控多个国家等于添加多个监控；同时监控文字+图形等于添加了2个监控。" placement="top">
                <el-button style="padding: 9px 15px;margin-left: 30px" round @click="add">添加商标</el-button>
              </el-tooltip>
            </div>

            <div class="totalMonitoring">
              <span>
                监控总量： <i class="active1">{{totalTimes}}</i>
              </span>
              <span>
                监控剩余量： <i class="active2">{{remainTimes}}</i>
              </span>
            </div>
          </div>
        </template>

        <template #imageUrl="{ row }">
          <img style="height: 100%; max-width: 100%" :src="returnUrl(row.image)" v-if="row.isImage == '图片'" alt=""/>

          <span style="display: flex; align-items: center" v-else>{{row.tmcn}}</span>
        </template>

        <template #term="{ row }">
          <div v-if="row.endDate!=''&&row.startDate!=''">{{row.startDate}} 至 {{row.endDate}}</div>
        </template>

        <template #monitorType="{ row }">
          <div>{{row.monitorType == 1 ? '近似监控' : '竞争对手监控'}}</div>
        </template>

        <template #state="{ row }">
          <div :style="{width: '98px', height: '34px', background: row.status == 20 ? '#f4f8ff' : '#f7f7f7', color: row.status == 20 ? '#698ee2' : '#aaacb3', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px'}">
            {{row.status == 20 ? '监控中' : row.status == 30 ? '已终止' : ''}}
          </div>
        </template>

        <template #operation="{ row }">
          <div style="display: flex;align-items: center;justify-content: space-between">
            <span
              style="color:#3b6bd8;cursor:pointer;"
              @click="viewMonitoring(row.id,row.cnApplicant,row.appno,row.image,row.cusCaseNo,row.country,row)"
              v-if="row.status>10"
              class="seeResults afterBreak"
            >
              查看结果
            </span>
            &nbsp;
            <span
              style="color:#3b6bd8;cursor:pointer;"
              @click="zhongzhi(row)"
              v-if="row.status == 20"
              class="seeResults"
            >
              <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="监控" transform="translate(-1728.000000, -538.000000)" fill="#3B6BD8" fill-rule="nonzero">
                        <g id="编组备份-5" transform="translate(1728.000000, 535.000000)">
                            <g id="终止" transform="translate(0.000000, 3.000000)">
                                <path d="M7,14.0000163 C3.15000367,14.0000163 0,10.8500126 0,7 C0,3.15000367 3.15000367,0 7,0 C10.8500126,0 14.0000163,3.15000367 14.0000163,7 C14.0000163,10.8500126 10.8500126,14.0000163 7,14.0000163 Z M7.00000815,1.16666347 C3.79166652,1.16666347 1.16666347,3.79166652 1.16666347,7.00000815 C1.16666347,10.2083498 3.79166652,12.8333528 7.00000815,12.8333528 C10.2083498,12.8333528 12.8333528,10.2083498 12.8333528,7.00000815 C12.8333528,3.79166652 10.2083498,1.16666347 7.00000815,1.16666347 Z" id="形状"></path>
                                <path d="M9.33334875,7.58334671 L4.66668121,7.58334671 C4.3166808,7.58334671 4.08334674,7.35001265 4.08334674,7.00001225 C4.08334674,6.65001184 4.3166808,6.41667778 4.66668121,6.41667778 L9.33334875,6.41667778 C9.68334916,6.41667778 9.91668322,6.65001184 9.91668322,7.00001225 C9.91668322,7.35001265 9.62501667,7.58334671 9.33334875,7.58334671 Z" id="路径"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
              终止
            </span>

            <span
              style="color:#3b6bd8;cursor:pointer;"
              v-if="row.status == 30"
              class="seeResults"
              @click="enableEven([row.id])"
            >
              <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="监控" transform="translate(-1728.000000, -443.000000)">
                        <g id="编组-2备份" transform="translate(1728.000000, 440.000000)">
                            <g id="启用" transform="translate(0.000000, 3.000000)">
                                <path d="M7,0 L7,0 C3.13401449,0 0,3.13401442 0,7 C0,10.8659856 3.13401449,14 7,14 L7,14 C10.8659855,14 14,10.8659856 14,7 C14,3.13401442 10.8659855,0 7,0 L7,0 Z M7.00000046,1.07692308 L7.0000002,1.07692308 C10.2712214,1.07692308 12.9230773,3.72877885 12.9230773,7 C12.9230773,10.2712212 10.2712214,12.9230769 7.0000002,12.9230769 L6.99999994,12.9230769 C3.72877872,12.9230769 1.07692289,10.2712212 1.07692289,7 C1.07692289,3.72877885 3.72877872,1.07692308 6.99999994,1.07692308 L7.00000046,1.07692308 Z" id="形状" fill="#3B6BD8" fill-rule="nonzero"></path>
                                <path d="M7,10 C8.65685086,10 10,8.65685792 10,7 C10,5.34314911 8.6568579,4 7,4 C5.34314914,4 4,5.34314208 4,7 C4,8.65685089 5.3431421,10 7,10 Z" id="路径" fill="#4270D7" fill-rule="nonzero"></path>
                                <polygon id="路径" points="0 0 14 0 14 14 0 14"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
              启用
            </span>

            <span
              :style="{color: row.status == 30 || row.modifyCount != 0 ? '#c0c4cc' : '#3b6bd8',cursor: row.status == 30 || row.modifyCount != 0 ? 'no-drop' : 'pointer'}"
              class="seeResults"
              @click="edit(row)"
            >
              <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g v-if="row.status == 30 || row.modifyCount != 0" id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="监控" transform="translate(-1799.000000, -443.000000)" fill="#c0c4cc" fill-rule="nonzero">
                        <g id="编组-3" transform="translate(1799.000000, 440.000000)">
                            <g id="修改" transform="translate(0.000000, 3.000000)">
                                <path d="M12.2056588,2.31662822 L10.4962167,0.608857138 C9.94311279,0.0557531956 9.02739992,0.0557531956 8.44923084,0.608857138 L0.555384853,8.50437414 C0.386612956,8.67314604 0.291365449,8.91377132 0.291365449,9.1543966 L0.194446933,11.6575679 L0.194446933,11.7294213 C0.217841058,11.9934407 0.314759573,12.234066 0.508596604,12.4028379 C0.677368502,12.5716098 0.917993782,12.6434632 1.15861906,12.6434632 L1.20707832,12.6434632 L3.66179038,12.5465446 C3.90241566,12.5465446 4.1179758,12.426232 4.2867477,12.2574601 L12.1822647,4.36361411 C12.7838279,3.78544504 12.7838279,2.87140317 12.2056588,2.31662822 Z M0.926348827,11.9132323 L0.999873218,9.83616822 L2.97667673,11.8129717 L0.926348827,11.9132323 Z M3.95254592,11.3367342 L1.47611075,8.86197004 L8.03147862,2.30660217 L10.5079138,4.78303734 L3.95254592,11.3367342 Z M11.7361053,3.55818783 L11.2097375,4.08288462 L8.72996034,1.60644944 L9.25799915,1.08175265 C9.35825968,0.98149212 9.48358535,0.931361853 9.60891101,0.931361853 C9.73423668,0.931361853 9.85956235,0.98149212 9.95982288,1.08175265 L11.7361053,2.8580351 C11.9349554,3.05688516 11.9349554,3.35766676 11.7361053,3.55818783 L11.7361053,3.55818783 Z M13.2266453,11.7544864 L5.69875021,11.7544864 C5.42136274,11.7544864 5.19744755,11.9784016 5.19744755,12.2557891 C5.19744755,12.5331766 5.42136274,12.7570918 5.69875021,12.7570918 L13.2266453,12.7570918 C13.5040327,12.7570918 13.7279479,12.5331766 13.7279479,12.2557891 C13.7279479,11.9784016 13.5040327,11.7544864 13.2266453,11.7544864 Z M6.37550881,10.91564 C6.37550881,11.1930274 6.599424,11.4169426 6.87681148,11.4169426 L13.2266453,11.4169426 C13.5040327,11.4169426 13.7279479,11.1930274 13.7279479,10.91564 C13.7279479,10.6382525 13.5040327,10.4143373 13.2266453,10.4143373 L6.87681148,10.4143373 C6.60109501,10.4143373 6.37550881,10.6382525 6.37550881,10.91564 Z" id="形状"></path>
                            </g>
                        </g>
                    </g>
                </g>

                <g v-else id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="监控" transform="translate(-1799.000000, -443.000000)" fill="#3B6BD8" fill-rule="nonzero">
                        <g id="编组-3" transform="translate(1799.000000, 440.000000)">
                            <g id="修改" transform="translate(0.000000, 3.000000)">
                                <path d="M12.2056588,2.31662822 L10.4962167,0.608857138 C9.94311279,0.0557531956 9.02739992,0.0557531956 8.44923084,0.608857138 L0.555384853,8.50437414 C0.386612956,8.67314604 0.291365449,8.91377132 0.291365449,9.1543966 L0.194446933,11.6575679 L0.194446933,11.7294213 C0.217841058,11.9934407 0.314759573,12.234066 0.508596604,12.4028379 C0.677368502,12.5716098 0.917993782,12.6434632 1.15861906,12.6434632 L1.20707832,12.6434632 L3.66179038,12.5465446 C3.90241566,12.5465446 4.1179758,12.426232 4.2867477,12.2574601 L12.1822647,4.36361411 C12.7838279,3.78544504 12.7838279,2.87140317 12.2056588,2.31662822 Z M0.926348827,11.9132323 L0.999873218,9.83616822 L2.97667673,11.8129717 L0.926348827,11.9132323 Z M3.95254592,11.3367342 L1.47611075,8.86197004 L8.03147862,2.30660217 L10.5079138,4.78303734 L3.95254592,11.3367342 Z M11.7361053,3.55818783 L11.2097375,4.08288462 L8.72996034,1.60644944 L9.25799915,1.08175265 C9.35825968,0.98149212 9.48358535,0.931361853 9.60891101,0.931361853 C9.73423668,0.931361853 9.85956235,0.98149212 9.95982288,1.08175265 L11.7361053,2.8580351 C11.9349554,3.05688516 11.9349554,3.35766676 11.7361053,3.55818783 L11.7361053,3.55818783 Z M13.2266453,11.7544864 L5.69875021,11.7544864 C5.42136274,11.7544864 5.19744755,11.9784016 5.19744755,12.2557891 C5.19744755,12.5331766 5.42136274,12.7570918 5.69875021,12.7570918 L13.2266453,12.7570918 C13.5040327,12.7570918 13.7279479,12.5331766 13.7279479,12.2557891 C13.7279479,11.9784016 13.5040327,11.7544864 13.2266453,11.7544864 Z M6.37550881,10.91564 C6.37550881,11.1930274 6.599424,11.4169426 6.87681148,11.4169426 L13.2266453,11.4169426 C13.5040327,11.4169426 13.7279479,11.1930274 13.7279479,10.91564 C13.7279479,10.6382525 13.5040327,10.4143373 13.2266453,10.4143373 L6.87681148,10.4143373 C6.60109501,10.4143373 6.37550881,10.6382525 6.37550881,10.91564 Z" id="形状"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
              修改
            </span>

            <span
              style="color:#3b6bd8;cursor:pointer;display: inline-flex;align-items: center"
              @click="del(row)"
              class="seeResults"
              :style="{color: !ifDeletable(row) ? '#c0c4cc' : '#3b6bd8',cursor: !ifDeletable(row) ? 'no-drop' : 'pointer'}"
            >
              <svg v-if="ifDeletable(row)" t="1712476624326" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4288" width="16" height="16">
                <path d="M695.8 901.4H319c-55.4 0-100.5-45.1-100.5-100.5V348.6h577.9v452.3c-0.1 55.4-45.1 100.5-100.6 100.5zM268.7 398.9v402c0 27.7 22.5 50.3 50.3 50.3h376.9c27.7 0 50.3-22.5 50.3-50.3v-402H268.7z" fill="#3B6BD8" p-id="4289"></path>
                <path d="M312.7 574.7c-6.9 0-12.6-5.6-12.6-12.6v-73.6c0-33.6 27.4-61 61-61h39.5c6.9 0 12.6 5.6 12.6 12.6s-5.6 12.6-12.6 12.6h-39.5c-19.8 0-35.9 16.1-35.9 35.9v73.6c0.1 6.9-5.6 12.5-12.5 12.5zM651.9 788.3c-13.9 0-25.1-11.2-25.1-25.1V486.8c0-13.9 11.2-25.1 25.1-25.1 13.9 0 25.1 11.2 25.1 25.1v276.4c0 13.9-11.2 25.1-25.1 25.1zM513.7 788.3c-13.9 0-25.1-11.2-25.1-25.1V549.6c0-13.9 11.2-25.1 25.1-25.1 13.9 0 25.1 11.2 25.1 25.1v213.6c0 13.9-11.2 25.1-25.1 25.1zM375.5 788.3c-13.9 0-25.1-11.2-25.1-25.1V625c0-13.9 11.2-25.1 25.1-25.1s25.1 11.2 25.1 25.1v138.2c0 13.9-11.2 25.1-25.1 25.1z" fill="#3B6BD8" p-id="4290"></path>
                <path d="M463.4 436.6m-12.6 0a12.6 12.6 0 1 0 25.2 0 12.6 12.6 0 1 0-25.2 0Z" fill="#3B6BD8" p-id="4291"></path>
                <path d="M834 298.4H180.8c-13.9 0-25.1-11.2-25.1-25.1s11.2-25.1 25.1-25.1H834c13.9 0 25.1 11.2 25.1 25.1s-11.2 25.1-25.1 25.1z" fill="#3B6BD8" p-id="4292"></path>
                <path d="M507.4 298.4c-55.4 0-100.5-45.1-100.5-100.5S452 97.4 507.4 97.4s100.5 45.1 100.5 100.5-45.1 100.5-100.5 100.5z m0-150.8c-27.7 0-50.3 22.5-50.3 50.3s22.5 50.3 50.3 50.3c27.7 0 50.3-22.5 50.3-50.3s-22.6-50.3-50.3-50.3z" fill="#3B6BD8" p-id="4293"></path>
              </svg>
              <svg v-if="!ifDeletable(row)" t="1712476624326" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4288" width="16" height="16">
                <path d="M695.8 901.4H319c-55.4 0-100.5-45.1-100.5-100.5V348.6h577.9v452.3c-0.1 55.4-45.1 100.5-100.6 100.5zM268.7 398.9v402c0 27.7 22.5 50.3 50.3 50.3h376.9c27.7 0 50.3-22.5 50.3-50.3v-402H268.7z" fill="#c0c4cc" p-id="4289"></path>
                <path d="M312.7 574.7c-6.9 0-12.6-5.6-12.6-12.6v-73.6c0-33.6 27.4-61 61-61h39.5c6.9 0 12.6 5.6 12.6 12.6s-5.6 12.6-12.6 12.6h-39.5c-19.8 0-35.9 16.1-35.9 35.9v73.6c0.1 6.9-5.6 12.5-12.5 12.5zM651.9 788.3c-13.9 0-25.1-11.2-25.1-25.1V486.8c0-13.9 11.2-25.1 25.1-25.1 13.9 0 25.1 11.2 25.1 25.1v276.4c0 13.9-11.2 25.1-25.1 25.1zM513.7 788.3c-13.9 0-25.1-11.2-25.1-25.1V549.6c0-13.9 11.2-25.1 25.1-25.1 13.9 0 25.1 11.2 25.1 25.1v213.6c0 13.9-11.2 25.1-25.1 25.1zM375.5 788.3c-13.9 0-25.1-11.2-25.1-25.1V625c0-13.9 11.2-25.1 25.1-25.1s25.1 11.2 25.1 25.1v138.2c0 13.9-11.2 25.1-25.1 25.1z" fill="#c0c4cc" p-id="4290"></path>
                <path d="M463.4 436.6m-12.6 0a12.6 12.6 0 1 0 25.2 0 12.6 12.6 0 1 0-25.2 0Z" fill="#c0c4cc" p-id="4291"></path>
                <path d="M834 298.4H180.8c-13.9 0-25.1-11.2-25.1-25.1s11.2-25.1 25.1-25.1H834c13.9 0 25.1 11.2 25.1 25.1s-11.2 25.1-25.1 25.1z" fill="#c0c4cc" p-id="4292"></path>
                <path d="M507.4 298.4c-55.4 0-100.5-45.1-100.5-100.5S452 97.4 507.4 97.4s100.5 45.1 100.5 100.5-45.1 100.5-100.5 100.5z m0-150.8c-27.7 0-50.3 22.5-50.3 50.3s22.5 50.3 50.3 50.3c27.7 0 50.3-22.5 50.3-50.3s-22.6-50.3-50.3-50.3z" fill="#c0c4cc" p-id="4293"></path>
              </svg>
              删除
            </span>

            <span
              style="color:rgb(235, 65, 66);cursor:pointer;"
              v-if="row.status == 10"
              class="seeResults"
            >
              监控创建中
            </span>
          </div>
        </template>

        <template #pager>
          <vxe-pager
            border
            background
            :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
            :current-page.sync="current"
            :page-size.sync="size"
            :total="total"
            @page-change="handleCurrentChange">
          </vxe-pager>
        </template>

        <!--自定义空数据模板-->
        <template #empty>
          <div>
            {{$t('personal_lang1.noDate')}}
          </div>
        </template>
      </vxe-grid>
    </div>

    <template>
      <el-dialog width="600px" :visible.sync="monitor" class="long-dialog">
        <div>
          <p class="monitorDialogTitle" @click="addOrEditData.monitorType = 1" :class="[this.addOrEditData.monitorType == 1 ? 'monitorActive' : '']">近似监控</p>
          <p class="monitorDialogTitle" @click="addOrEditData.monitorType = 2" :class="[this.addOrEditData.monitorType == 2 ? 'monitorActive' : '']">竞争对手监控</p>
        </div>
        <div class="monitor clearfix">
          <div class="mainBody">
            <div>
              <!--国家/地区-->
              <div class="check">
                <span class="title" style="vertical-align: middle">
                  所属国家/地区
                  <span class="requiredStar">*</span>
                </span>

                <el-select
                  v-model="addOrEditData.countrySet"
                  multiple
                  clearable
                  ref="fuzzySearch"
                  placeholder="请选择"
                  @change="SelectCountryFn"
                  style="width:380px"
                >
                  <el-option
                    v-for="item in [{label: '', value: ''}]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div @click.stop style="display: flex; justify-content: space-between; margin-bottom: 5px">
                      <el-input
                        clearable
                        size="small"
                        placeholder="输入关键字进行过滤"
                        v-model="filterText"
                      />

<!--                      <el-button style="height: 29px;margin-top: 2px" type="primary" @click.stop="confirm()" size="mini">确认</el-button>-->
                    </div>

                    <el-tree
                      :default-expanded-keys="[0]"
                      class="filter-tree"
                      :filter-node-method="filterNode"
                      @check-change="handleCheckChange"
                      ref="tree"
                      :data="dataList"
                      show-checkbox
                      node-key="code"
                      :props="defaultProps"
                    />
                  </el-option>
                </el-select>
              </div>

              <template v-if="addOrEditData.monitorType == 1">
                <!--商标图样-->
                <div class="check">
                <span class="title" style="vertical-align: middle">
                  商标图样
                </span>

                  <span class="right" style="vertical-align: middle;">
                  <el-upload
                    drag
                    :headers="headers"
                    action="/admin/sys-file/upload"
                    multiple
                    class="upload-drag"
                    :auto-upload="true"
                    :on-change="uploadChangeFn"
                    :show-file-list="false"
                    :before-upload="beforeAvatarUpload"
                    :on-success="uploadFn"
                  >

                    <i v-if="!addOrEditData.iconImageUrl" style="margin-top: 14px" class="el-icon-upload"/>

                    <img :src="returnUrl(addOrEditData.iconImageUrl)" v-if="addOrEditData.iconImageUrl" class="upload-img"/>

                    <p v-if="addOrEditData.iconImageUrl" class="delete" @click.stop="deleteImg">{{$t("update_lang.btn_delete")}}</p>

                    <p v-if="!addOrEditData.iconImageUrl" style="font-size: 12px;color:#999999;">请上传商标图样，需<5MB</p>

                    <div class="el-upload-disabled-shade"/>
                  </el-upload>
                </span>
                </div>
                <!--商标名称-->
                <div style="display: flex;align-items: center" class="check">
                <span class="title">
                  商标名称
                </span>

                  <span style="display: flex;align-items: center" class="right">
                  <el-input
                    class="el-input-long-officialt active"
                    v-model="addOrEditData.trademarkName"
                    placeholder="请输入文字"
                    :maxlength=20
                  />

                  <el-tooltip class="item" effect="light" content="" placement="top-start">
                     <div slot="content">请注意输入的商标名称语种与选择的国家是否匹配。<br/>例如，监控商标【蓝灯鱼】，监控国家【美国】，监控结果为空。</div>
                    <i style="margin-left: 10px;font-size: 18px" class="el-icon-info"/>
                  </el-tooltip>
                </span>
                </div>
                <!--商标类别-->
                <div class="check">
                <span class="title">
                  监控类别
                  <span class="requiredStar">*</span>
                </span>

                  <span class="right niceCode">
                  <el-input
                    class="leftText el-input-long-officialt backgroundColorGrey"
                    :disabled="true"
                    placeholder="请通过右侧选择按钮选定类别"
                    v-model="addOrEditData.niceSet"
                  />

                  <span class="rightText" @click="open();categoryType(true)" style="cursor: pointer">{{$t('query_lang.please_choose')}}</span>
                </span>
                </div>
              </template>

              <template v-else>
                <!--申请人名称-->
                <div style="display: flex;align-items: center" class="check">
                <span class="title">
                  申请人名称
                  <span class="requiredStar">*</span>
                </span>
                <span style="display: flex;align-items: center" class="right">
                <el-select @change="$forceUpdate()"  clearable remote ref="nameSel"  :remote-method="(val) => remotePeopleMethod(val, otherVal)" style="width: 380px" v-model="addOrEditData.applyPersonName" filterable placeholder="请输入申请人名称">
                  <el-option
                    @click.native="optionEvent(item)"
                    v-for="item in applyPersonNameArr"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </span>
                </div>
              </template>

              <!--拓展监控(撤三监控/续展监控)-->
              <div class="check" v-if="this.addOrEdit == 0 && this.containCN">
                <el-checkbox v-model="addOrEditData.revokeThreeFlag" :label=true>撤三监控</el-checkbox>
                <el-checkbox v-model="addOrEditData.renewalFlag" :label=true>续展监控</el-checkbox>
              </div>

              <div v-if="(addOrEditData.revokeThreeFlag || addOrEditData.renewalFlag) && this.containCN" class="check">
                <span class="title">
                  商标申请号
                  <span class="requiredStar">*</span>
                </span>

                <span class="right">
                  <el-input placeholder="商标申请号" v-model="addOrEditData.trademarkApplyNo"/>
                </span>
              </div>

              <div v-if="addOrEditData.revokeThreeFlag && this.containCN" class="check">
                <span>
                  是否是本商标的专有权人
                  <span class="requiredStar">*</span>
                </span>

                <span style="display: inline-block; margin-left: 20px">
                  <el-select v-model="addOrEditData.trademarkOwnFlag" style="width: 300px">
                    <el-option label="否" :value = false />
                    <el-option label="是" :value = true />
                  </el-select>
                </span>
              </div>

              <div v-if="addOrEditData.renewalFlag && this.containCN" class="check">
                <span class="title">
                  商标类别
                  <span class="requiredStar">*</span>
                </span>

                <span class="right niceCode">
                  <el-input
                    class="leftText el-input-long-officialt"
                    :disabled="true"
                    placeholder="商标类别仅支持选择 1 个"
                    v-model="addOrEditData.renewalNiceCode"
                  />

                  <span class="rightText" @click="open();categoryType(false)" style="cursor: pointer">{{$t('query_lang.please_choose')}}</span>
                </span>
              </div>

              <div style="margin-top: 20px;text-align: center; margin-bottom: 0" class="check">
                <el-button style="width: 100px;" :disabled="isDisabled" type="primary" @click="save">保存</el-button>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <!--尼斯分类-->
      <el-dialog :visible.sync="niceVisible" class="long-dialog-old" style="margin-top: 23px">
        <div class="long-inner" style="width: 741px; height: 351px;">
          <classify @closeFn="close" @classifyConfirm="classifyType" class="high-classify" style="height: 532px; width: 741px"/>
        </div>
      </el-dialog>

      <el-dialog :visible.sync="dialogVisibles" :close-on-click-modal="false" custom-class="login_dialog" :before-close="closeEvent">
        <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"/>
      </el-dialog>
    </template>
  </div>
</template>

<script>
  import classify from "@/components/query/public/classInTheNiceClassification";
  import login from "@/components/common/login"
  import moment from 'moment'
  import {customStart} from "@/request/trademarkPayment";

  export default {
    components: { classify, login },

    data() {
      return {
        monitorType:[
          {
            label: '近似监控',
            value: 1
          },
          {
            label: '竞争对手监控',
            value: 2
          }
        ],
        //监控商标
        monitoringTrademark: "",
        //选择时间
        start_date_1: '',
        start_date_2: '',
        //结束日期
        end_date_1: '',
        end_date_2: '',
        //分页信息
        current: 1,
        size: 50,
        total: 0,
        //表格过滤使用
        isImage: '',
        appnos: '',
        countryNames: '',
        intCls: '',
        cnApplicant: '',

        //表格展示配置
        gridOptions: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          toolbarConfig: {
            slots: {
              buttons: 'toolbar_buttons'
            }
          },
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            height: '720px',
            stripe: true,
            cellStyle: this.cellStyle
          },
          columns: [
            {
              type: 'checkbox',
              width: '50', fixed: 'left'
            },
            {
              field: 'countryNames',
              title: '所属国家/地区',
              width: '160',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'imageUrl', width: '120', title: '监控商标', slots: {
                // 使用插槽模板渲染
                default: 'imageUrl',
              }
            },
            {
              field: 'isImage',
              title: '商标类型',
              filters: [
                {label: '文字', value: '0'},
                {label: '图片', value: '1'}
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: false, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'intCls',
              title: '监控类别',
              filters: [
                {label: '01', value: '01'},
                {label: '02', value: '02'},
                {label: '03', value: '03'},
                {label: '04', value: '04'},
                {label: '05', value: '05'},
                {label: '06', value: '06'},
                {label: '07', value: '07'},
                {label: '08', value: '08'},
                {label: '09', value: '09'},
                {label: '10', value: '10'},
                {label: '11', value: '11'},
                {label: '12', value: '12'},
                {label: '13', value: '13'},
                {label: '14', value: '14'},
                {label: '15', value: '15'},
                {label: '16', value: '16'},
                {label: '17', value: '17'},
                {label: '18', value: '18'},
                {label: '19', value: '19'},
                {label: '20', value: '20'},
                {label: '21', value: '21'},
                {label: '22', value: '22'},
                {label: '23', value: '23'},
                {label: '24', value: '24'},
                {label: '25', value: '25'},
                {label: '26', value: '26'},
                {label: '27', value: '27'},
                {label: '28', value: '28'},
                {label: '29', value: '29'},
                {label: '30', value: '30'},
                {label: '31', value: '31'},
                {label: '32', value: '32'},
                {label: '33', value: '33'},
                {label: '34', value: '34'},
                {label: '35', value: '35'},
                {label: '36', value: '36'},
                {label: '37', value: '37'},
                {label: '38', value: '38'},
                {label: '39', value: '39'},
                {label: '40', value: '40'},
                {label: '41', value: '41'},
                {label: '42', value: '42'},
                {label: '43', value: '43'},
                {label: '44', value: '44'},
                {label: '45', value: '45'}
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'cnApplicant',
              title: '监控申请人',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
                options: [],
                placeholder: '请选择',
                props: {placeholder: '请选择'}
              }
            },
            {
              field: 'appno',
              title: '期限',
              slots: {
                // 使用插槽模板渲染
                default: 'term'
              }
            },
            {
              field: 'showName',
              title: '监控类型',
              slots: {
                default: 'monitorType'
              }
            },
            {
              field: 'appno',
              title: '状态',
              width: '120',
              slots: {
                // 使用插槽模板渲染
                default: 'state'
              },
              filters: [
                {label: '监控中', value: 20},
                {label: '已终止', value: 30}
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'showAdress', title: '操作', visible: true,
              width: "260px",
              fixed:"right",
              slots: {
                default: 'operation'
              }
            }
          ],
          importConfig: {
            remote: true,
            importMethod: this.importMethod,
            types: ['xlsx'],
            modes: ['insert']
          },
          exportConfig: {
            remote: true,
            exportMethod: this.exportMethod
          },
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        //总次数
        totalTimes: '',
        //使用次数
        usageTimes: '',
        //剩余次数
        remainTimes: '',

        //新增监控dialog
        monitor: false,
        //新增监控或修改监控 0-新增 1-修改
        addOrEdit:0,
        addOrEditData:{
          id:"",
          countrySet:[],
          niceSet:"",
          trademarkName:"",
          wordImageUrl:"",
          iconImageUrl:"",
          remarks:"",
          monitorType:1,
          sourctType:0,
          revokeThreeFlag:undefined,
          renewalFlag:undefined,
          trademarkApplyNo:undefined,
          trademarkOwnFlag:undefined,
          renewalNiceCode:undefined,
          applyPersonName:undefined
        },
        containCN:false,
        beforeEditCountryListNum:0,
        //申请人联想
        applyPersonNameArr:[],
        //国家筛选
        filterText: '',
        //国家树形表
        dataList: [
          {
            code: 0,
            label: '全选',
            children: [
              {
                code: 1,
                label: '亚洲',
                children: [
                  {
                    label: '中国大陆',
                    code: 'CN',
                    ingSrc: '/images/national/zgdl.jpg'
                  },
                  {
                    label: '中国香港',
                    code: 'HK',
                    ingSrc: '/images/national/zgxg.jpg'
                  },
                  {
                    label: '印度尼西亚',
                    code: 'ID',
                    ingSrc: '/images/national/ydnxy.jpg'
                  },
                  {
                    label: '印度',
                    code: 'IN',
                    ingSrc: '/images/national/yd.jpg'
                  },
                  {
                    label: '日本',
                    code: 'JP',
                    ingSrc: '/images/national/rb.jpg'
                  },
                  {
                    label: '土耳其',
                    code: 'TR',
                    ingSrc: '/images/national/trq.jpg'
                  },
                  {
                    label: '韩国',
                    code: 'KR',
                    ingSrc: '/images/national/hg.jpg'
                  },
                  {
                    label: '中国澳门',
                    code: 'MO',
                    ingSrc: '/images/national/am.jpg'
                  },
                  {
                    label: '马来西亚',
                    code: 'MY',
                    ingSrc: '/images/national/mlxy.jpg'
                  },
                  {
                    label: '菲律宾',
                    code: 'PH',
                    ingSrc: '/images/national/flb.jpg'
                  },
                  {
                    label: '新加坡',
                    code: 'SG',
                    ingSrc: '/images/national/xjp.jpg'
                  },
                  {
                    label: '中国台湾',
                    code: 'TW',
                    ingSrc: '/images/national/zgtw.png'
                  },
                  {
                    label: '泰国',
                    code: 'TH',
                    ingSrc: '/images/national/tgs.jpg'
                  },
                  {
                    label: '阿拉伯联合酋长国',
                    code: 'AE',
                    ingSrc: '/images/national/alq.jpg'
                  }
                ]
              },
              {
                code: 2,
                label: '欧洲',
                children: [
                  {
                    label: '奥地利',
                    code: 'AT',
                    ingSrc: '/images/national/adl.JPG'
                  },
                  {
                    label: '瑞士',
                    code: 'CH',
                    ingSrc: '/images/national/rs.jpg'
                  },
                  {
                    label: '德国',
                    code: 'DE',
                    ingSrc: '/images/national/dg.jpg'
                  },
                  {
                    label: '丹麦',
                    code: 'DK',
                    ingSrc: '/images/national/dm.jpg'
                  },
                  {
                    label: '挪威',
                    code: 'NO',
                    ingSrc: '/images/national/nw.jpg'
                  },
                  {
                    label: '西班牙',
                    code: 'ES',
                    ingSrc: '/images/national/xby.jpg'
                  },
                  {
                    label: '欧盟',
                    code: 'EU',
                    ingSrc: '/images/national/om.jpg'
                  },
                  {
                    label: '芬兰',
                    code: 'FI',
                    ingSrc: '/images/national/fl.jpg'
                  },
                  {
                    label: '法国',
                    code: 'FR',
                    ingSrc: '/images/national/fg.jpg'
                  },
                  {
                    label: '英国',
                    code: 'GB',
                    ingSrc: '/images/national/yg.jpg'
                  },
                  {
                    label: '葡萄牙',
                    code: 'PT',
                    ingSrc: '/images/national/pty.jpg'
                  },
                  {
                    label: '希腊',
                    code: 'GR',
                    ingSrc: '/images/national/xl.jpg'
                  },
                  {
                    label: '匈牙利',
                    code: 'HU',
                    ingSrc: '/images/national/xyl.jpg'
                  },
                  {
                    label: '爱尔兰',
                    code: 'IE',
                    ingSrc: '/images/national/ael.jpg'
                  },
                  {
                    label: '冰岛',
                    code: 'IS',
                    ingSrc: '/images/national/bd.jpg'
                  },
                  {
                    label: '意大利',
                    code: 'IT',
                    ingSrc: '/images/national/ydl.jpg'
                  },
                  {
                    label: '瑞典',
                    code: 'SE',
                    ingSrc: '/images/national/rd.jpg'
                  },
                  {
                    label: '俄罗斯',
                    code: 'RU',
                    ingSrc: '/images/national/elss.jpg'
                  },
                  {
                    label: '比荷卢经济联盟',
                    code: 'BX',
                    ingSrc: '/images/national/bhl.png'
                  }
                ]
              },
              {
                code: 3,
                label: '北美洲',
                children: [
                  {
                    label: '加拿大',
                    code: 'CA',
                    ingSrc: '/images/national/jnd.jpg'
                  },
                  {
                    label: '墨西哥',
                    code: 'MX',
                    ingSrc: '/images/national/mxg.jpg'
                  },
                  {
                    label: '美国',
                    code: 'US',
                    ingSrc: '/images/national/mg.jpg'
                  },
                ]
              },
              {
                code: 4,
                label: '南美洲',
                children: [
                  {
                    label: '阿根廷',
                    code: 'AR',
                    ingSrc: '/images/national/agt.jpg'
                  },
                  {
                    label: '巴西',
                    code: 'BR',
                    ingSrc: '/images/national/bx.jpg'
                  },
                  {
                    label: '智利',
                    code: 'CL',
                    ingSrc: '/images/national/zl.jpg'
                  },
                ]
              },
              {
                code: 5,
                label: '非洲',
                children: [
                  {
                    label: '南非',
                    code: 'ZA',
                    ingSrc: '/images/national/nf.jpg'
                  }
                ]
              },
              {
                code: 6,
                label: '大洋洲',
                children: [
                  {
                    label: '澳大利亚',
                    code: 'AU',
                    ingSrc: '/images/national/adly.jpg'
                  },
                  {
                    label: '新西兰',
                    code: 'NZ',
                    ingSrc: '/images/national/xxl.jpg'
                  }
                ]
              },
              {
                code: 7,
                label: '知识产权组织',
                children: [
                  {
                    label: '世界知识产权组织国际局',
                    code: 'WO',
                    ingSrc: '/images/national/zscq.jpg'
                  }
                ]
              }
            ]
          },
        ],
        //国家树形表配置
        defaultProps: {
          children: 'children',
          label: 'label',
          id: 'code'
        },
        //上传图片header
        headers: {
          'Authorization': "Bearer " + this.tool.getCookie("auth"),
          'TENANT-ID': '4'
        },

        //是否禁止新增监控(防止多次提交使用)
        isDisabled: false,

        //类别选择浮窗
        niceVisible: false,
        //商标监控种类选择-0 商标续展种类选择-1
        monitorCategoryOrRenewalCategory:0,

        //登录浮窗
        dialogVisibles: false,
        dialogClose: false,
      }
    },

    async mounted() {
      this.isDisabled = false
      // setTimeout( async ( ) => {
        if(this.$route.params.flag){
          this.$route.params.id.split(';').map(async item => {
            await this.opens(item)
          })
        }
      // }, 2000)

      //数据初始化
      await this.init()
    },

    watch: {
      monitor(newVal,oldVal){
        if(newVal){
          this.applyPersonNameArr = []
        }
      },
      //新增或修改商标，监控国家过滤
      filterText(val) {
        this.$refs.tree[0].filter(val)
      },
    },

    methods: {
      deleteMonitoring(id){
        this.$axios
          .post("/trademark/monitoring/batch/delete/monitoring",{
            monitoringIdSet: [Number(id)]
          }).then(({data}) => {
          if(data.code == 0){
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }

        })
      },
      ifDeletable(row){
        //可删除监控的条件
        return true
      },
      //删除
      del(row){
        if (!this.ifDeletable(row)){
          return
        }
        if(row.status == 30) {
          this.deleteMonitoring(row.id)
        }else {
          this.$confirm('该商标正在监控中，请确定是否要立即删除?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.deleteMonitoring(row.id)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
        }
      },
      //开启服务
      opens(id){
         customStart(id).then(async res => {
           setTimeout(async () => {
             switch (localStorage.getItem('flag')) {
               case '1':
                 this.addOrEditData = JSON.parse(localStorage.getItem('addOrEditData'))
                 await this.createdOrderFn()
                 break;
               case '2':
                 // await this.renew( JSON.parse(localStorage.getItem('monitoringIdSet')))
                 break;
               case '3':
                 // await this.enableEven( JSON.parse(localStorage.getItem('monitoringIdSet')))
                 break;
             }
             await this.monitorRemaining()
           },2000)

        })
      },
      onTypeBlur(e) {
        if (e.target.value) {
          // this.type = e.target.value;
          // 如果是对象，要使用this.$set方法
          this.$set('addOrEditData', 'applyPersonName', e.target.value)
        }
      },
      uploadChangeFn(file, fileList) {
        let imgSize = Number(file.size / 1024 / 1024);

        if (imgSize > 3) {
          this.$message('文件大小不能超过3MB，请重新上传');
          this.materialList[scope.$index].fileList.splice(scope.index, 1);
          return;
        }
      },
      optionEvent(val){
        this.addOrEditData.applyPersonName = val

      },
      remotePeopleMethod(val){
        this.addOrEditData.applyPersonName = val
        if(this.addOrEditData.countrySet.length > 0){
          this.applicantHit(val)
        }else {
          this.$message('为了为您提供更准确地结果，请您先选择国家/地区');
        }
      },
      //根据国家联想申请人名称
      applicantHit(val){
        let arr = []
        this.addOrEditData.countrySet.map(item => {
          arr.push(this.searchCountryCodeByName(item))
        })
        this.$axios
          .post("/common/query/applicantHit",{
            country:arr.join(';'),
            applicant: val
          }).then(({data}) => {
            this.applyPersonNameArr = data
            return;
          })
      },
      handleCheckChange(){
        this.confirm()
      },
      //判断用户是否登录状态
      async init() {
        //若已经登录，则获取监控列表
        if (this.tool.getCookie("auth")) {
          await this.getList()
        }
        //若未登录，则跳出登录框
        else { this.dialogVisibles = true }
      },
      //获取商标监控列表
      getList() {
        let obj = {
          "monitorTrademark": this.monitoringTrademark,
          "startDateBegin": this.start_date_1,
          "startDateEnd": this.start_date_2,
          "finishDateBegin": this.end_date_1,
          "finishDateEnd": this.end_date_2,
          "current": this.current,
          "size": this.size,
          "monitorCategory": this.isImage,
          "status": this.appnos,
          "country": this.countryNames,
          "intcls": this.intCls,
          "applicant": this.cnApplicant
        }

        this.$axios.get("/trademark/monitoring/getUserMonitorings", { params: obj })
          .then(({data}) => {
            this.monitorRemaining()
            if (data.code == 0) {
              if (data.data.records.length) {
                this.gridOptions.data = data.data.records.map(item => {
                  if(item.monitorCategory == 0){
                    item.isImage = '文字'
                  }
                  if(item.monitorCategory == 1){
                    item.isImage = '图片'
                  }
                  return item;
                })
                this.total = data.data.total
                this.size = data.data.size
              }
              else {
                this.gridOptions.data = []
              }
            }
          })
      },
      //剩余监控个数
      async monitorRemaining() {
        await this.$axios
          .get("/product/servicemanage/getMonitorTimes").then(({data}) => {
            if (data.code == 0) {
              //总次数
              this.totalTimes = data.data.totalTimes
              //使用次数
              this.usageTimes = data.data.usageTimes
              //剩余次数
              this.remainTimes = data.data.remainTimes
            }
          })
      },

      //跳转
      skip(item) {this.$router.push({path: item})},
      //重置
      resetCondtion() {
        //监控商标
        this.monitoringTrademark = ''
        //开始和结束日期
        this.start_date_1 = ''
        this.start_date_2 = ''
        this.end_date_1 = ''
        this.end_date_2 = ''
        this.getList()
      },
      //本月到期
      dueMonth() {
        this.end_date_1 = this.Datestartstop()[0]
        this.end_date_2 = this.Datestartstop()[1]

        this.getList()
      },
      //本月起始时间-本月结束时间
      Datestartstop() {
        function getMonth(type, months) {
          var d = new Date()
          var year = d.getFullYear()
          var month = d.getMonth() + 1

          if (Math.abs(months) > 12) { months = months % 12 }

          if (months != 0) {
            if (month + months > 12) {
              year++
              month = (month + months) % 12
            }
            else if (month + months < 1) {
              year--
              month = 12 + month + months
            }
            else {
              month = month + months
            }
          }

          month = month < 10 ? "0" + month : month

          var firstday = year + "-" + month + "-" + "01"

          var lastday = ""

          if (month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" || month == "12") { lastday = year + "-" + month + "-" + 31 }
          else if (month == "02") {
            if ((year % 4 == 0 && year % 100 != 0) || (year % 100 == 0 && year % 400 == 0)) { lastday = year + "-" + month + "-" + 29 }
            else { lastday = year + "-" + month + "-" + 28 }
          }
          else { lastday = year + "-" + month + "-" + 30 }

          var day = ""
          if (type == "s") { day = firstday }
          else { day = lastday }

          return day
        }

        return [getMonth("s", 0), getMonth("e", 0)]
      },

      //获取展示表格cellClassName(图片列新增class=col-orange)
      cellClassName({row, column, $columnIndex}) {
        if ($columnIndex == 2) {
          return 'col-orange'
        }
      },
      //本月到期的数据的行颜色高亮
      rowStyle({row, rowIndex, $rowIndex}) {
        if (row.endDate && this.getDates(row.endDate)) {
          return 'background: #fff3e0'
        }
      },

      //获取当前年月日
      getDates(value) {
        //获取当年月日时分秒 2022-09-26 14:05:59
        if(!value) return
        if (value.split('-')[0] == moment().format('YYYY-MM-DD').split('-')[0] && value.split('-')[1] == moment().format('YYYY-MM-DD').split('-')[1]) {
          return true
        }
        else {
          return false
        }
      },

      //表格过滤变化触发
      filterChange() {
        let getCheckedFilters = this.$refs.xGrid.getCheckedFilters()
        this.countryNames = ''
        this.isImage = ''
        this.intCls = ''
        this.appnos = ''
        this.cnApplicant = ''

        getCheckedFilters.map(item => {
          switch (item.property) {
            //类别
            case "countryNames":
              this.countryNames = item.datas[0]
              break
            //监控申请人
            case "cnApplicant":
              this.cnApplicant = item.datas[0]
              break
            //监控类型
            case "isImage":
              this.isImage = item.values.join(';')
              break
            //注册号
            case "intCls":
              this.intCls = item.values.join(';')
              break
            //国家/地区
            case "appno":
              this.appnos = item.values.join(';')
              break
          }
        })

        this.getList()
      },

      //一键续费
      renewal() {
        //一键续费只支持状态为监控中的商标
        let data = this.$refs.xGrid.getCheckboxRecords()

        if (data.length == 0) {
          return
        }

        data.map(item => {
          if (item.status != 20) {
            this.$message({
              showClose: true,
              message: '抱歉，一键续费只支持状态为监控中的商标；一键启用只支持状态为已终止的商标，请您重新选择。'
            })
            throw new Error()
          }
        })

        //要续费的id
        this.renew(data.map(item => item.id))
      },
      //续费是否成功
      renew(value) {
        localStorage.setItem('monitoringIdSet',JSON.stringify(value))
        this.$axios
          .post("/trademark/monitoring/renewalMonitoringTask", {monitoringIdSet: value})
          .then(({data}) => {
            if (data.code == 0) {
              //续费商标监控任务成功
              this.getList()
              this.$message({
                showClose: true,
                message: '续费商标监控任务成功'
              })
            }

            else if (data.code == 900) {
              this.$confirm(data.data.paymentMessage, '提示', {confirmButtonText: '立即支付', cancelButtonText: '暂不支付', type: 'warning'})
                .then(() => {this.createOrder(data.data,2)})
                .catch(() => {this.$message({type: 'info', message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.remainTimes}个` : `本次启用失败。剩余个数还是${this.remainTimes}个`})})
            }

            else if (data.code == 1) {this.$message({showClose: true, message: '启用商标监控任务失败'})}
          })
      },
      //创建续费订单
      createOrder(object,value) {
        localStorage.setItem('flag',value ? value : 1)
        this.$axios
          .post("/order/order/createMonitorRenewalOrder", {
            "monitoringIds": object.monitoringIdSet.join(";"),
            "source": object.paymentSource,
            "orderType": 'tm_monitor',
            "currency": 'CNY',
            "quantity": object.paymentCount
          })
          .then(({data}) => {
            if (data.code == 0) {
              this.$router.push({
                path: '/pay/orderInfo',
                query: {
                  id: object.prodServiceId,
                  modeType: '',
                  tariff: data.data.productAmount,
                  name: '',
                  discountFlag: 0,
                  monitorFlag: object.paymentCount,
                  flag:  2,
                  orderNum: data.data.orderNum,
                  ids:  data.data.id,
                }
              })
            }
          })
      },

      //一键启用
      enable() {
        //一键启用只支持状态为已终止的商标
        let data = this.$refs.xGrid.getCheckboxRecords()

        if (data.length == 0) { return }

        try {
          data.map(item => {
            if (item.status != 30) {
              this.$message({
                showClose: true,
                message: '抱歉，一键续费只支持状态为监控中的商标；一键启用只支持状态为已终止的商标，请您重新选择。'
              })

              throw new Error()
            }
          })
        }
        catch (e) { return }

        this.enableEven(data.map(item => item.id))
      },

      //新增商标监控
      add() {
        this.addOrEdit = 0
        this.addOrEditData = {
          id:"",
          countrySet:[],
          niceSet:"",
          trademarkName:"",
          wordImageUrl:"",
          iconImageUrl:"",
          remarks:"",
          monitorType:1,
          sourctType:0,

          revokeThreeFlag:undefined,
          renewalFlag:undefined,
          trademarkApplyNo:undefined,
          trademarkOwnFlag:undefined,
          renewalNiceCode:undefined,
        }

        this.monitor = true
        this.$nextTick(()=>{
          this.$refs.tree[0].setCheckedKeys([])
        })
      },
      //编辑商标监控
      edit(row){
        if(row.status == 30 || row.modifyCount != 0) return
        this.addOrEdit = 1

        let rowData = row

        let countryNameList = []
        rowData.countryNames.split(",").map(item=>{
          if (item == "中国"){
            countryNameList.push("中国大陆")
          }
          else {
            countryNameList.push(item)
          }
        })

        this.beforeEditCountryListNum = countryNameList.length

        this.addOrEditData = {
          id:rowData.id,
          countrySet:countryNameList,
          niceSet:rowData.intCls && rowData.intCls.split(";"),
          trademarkName:rowData.tmcn,
          wordImageUrl:"",
          iconImageUrl: rowData.isImage == "图片" ? rowData.image : "",
          remarks:rowData.remarks,
          monitorType: row.monitorType,
          sourceType: rowData.sourceType,
          applyPersonName: rowData.cnApplicant
        }

        this.monitor = true

        this.$nextTick(()=>{
          let countryData = this.$refs.xGrid.getCheckboxRecords()[0].country
          let countryList = []
            countryData.split(",").map(item=>{
            countryList.push(item)
          })

          this.$refs.tree[0].setCheckedKeys(countryList)
        })
      },
      //给图片添加地址
      returnUrl(url) {
        if (url.startsWith('/group')) return "https://tmimage.lanternfish.cn"+url
        else return url
      },

      //查看监控结果详情
      viewMonitoring(id, cnApplicant, appno, imgUrl, cusCaseNo, country, row) {
        localStorage.setItem("imgUrl", imgUrl)
        localStorage.setItem("selectedcountry", country.split(";"))
        this.$router.push({
          path: "/monitoringResult",
          query: {
            step: 2,
            md: "t1_11",
            id: id,
            ca: cnApplicant,
            no: appno,
            youRef: cusCaseNo,
            country,
            content: row.isImage == "图片" ? row.image : row.tmcn
          }
        })
      },

      //终止监控
      zhongzhi(item){
        this.$axios.post("/trademark/monitoring/stopMonitoringTask", {monitoringId: item.id})
          .then(({data}) => {
            if (data.code != 0) { this.$message("终止商标监控任务失败") }
            else { this.$message("终止商标监控任务成功") }
            //获取列表数据
            this.getList()
          })
      },
      //启用监控
      enableEven(value) {
        localStorage.setItem('monitoringIdSet',JSON.stringify(value))
        this.$axios
          .post("/trademark/monitoring/startMonitoringTask", {monitoringIdSet: value})
          .then(({data}) => {
            if (data.code == 0) {
              //终止商标监控任务成功
              this.getList()
              this.$message({showClose: true, message: '启用商标监控任务成功'})
            }
            else if (data.code == 900) {
              this.$confirm(data.data.paymentMessage, '提示', {confirmButtonText: '立即支付', cancelButtonText: '暂不支付', type: 'warning'})
                .then(() => { this.createOrder(data.data,3)} )
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.remainTimes}个` : `本次启用失败。剩余个数还是${this.remainTimes}个`
                  })
                })
            }
            else if (data.code == 1) {
              this.$message({
                showClose: true,
                message: '启用商标监控任务失败'
              })
            }
          })
      },

      //表格页码变化触发
      handleCurrentChange({currentPage, pageSize}) {
        this.current = currentPage
        //获取列表数据
        this.getList()
      },

      //新增或修改商标监控国家变化触发
      SelectCountryFn(val) {
        let arr = []
        if (val.length == 0) {
          this.$refs.tree[0].setCheckedKeys([])
          this.containCN = false
        }
        else {
          this.allCountry.map(item => {
            val.map(items => {
              if (item.city == items) {
                arr.push([item.code])
              }
              return
            })
            return
          })
          this.$refs.tree[0].setCheckedKeys(arr)

          let CN = false
          val.map(item=>{
            if (item == '中国大陆'){
              CN = true
            }
          })
          this.containCN = CN
          if (this.containCN == false){
            this.addOrEditData.revokeThreeFlag = undefined
            this.addOrEditData.renewalFlag = undefined
            this.addOrEditData.trademarkApplyNo = undefined
            this.addOrEditData.trademarkOwnFlag = undefined
            this.addOrEditData.renewalNiceCode = undefined
          }
        }
      },

      //新增或修改商标监控国家确定触发
      confirm() {
        this.addOrEditData.countrySet = []

        //this.$refs.tree[0].getCheckedKeys() 内存储树形结构选中项
        // 0 1 2 3 4 5 6 7表示 全选 亚洲 欧洲 北美洲 南美洲 非洲 大洋洲 世界知识产权组织
        // 其余则为国家简写 如 中国大陆->CN

        if (this.$refs.tree[0].getCheckedKeys().includes(0)) {
          //若存在 0 则表示全选，将所有国家/地区插入this.addOrEditData.countrySet
          this.allCountry.map(item => {
            this.addOrEditData.countrySet.push(item.city)
            return
          })
        }

        else {
          this.allCountry.map(item => {
            this.$refs.tree[0].getCheckedKeys().map(items => {
              if (item.code == items) {
                this.addOrEditData.countrySet.push(item.city)
                return
              }
            })
            return
          })
        }

        if (this.$refs.tree[0].getCheckedKeys().includes("CN")){
          this.containCN = true
        }
        else {
          this.containCN = false
        }

        // this.$refs.fuzzySearch.blur()
      },
      //新增监控选择国家过滤方法
      filterNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },

      //新增监控图片上传前的钩子
      beforeAvatarUpload(file) {
        const isLt5M = file.size < 1024 * 1024 * 5
        if (!isLt5M) {
          this.$message.error('上传图片大小不能超过 5MB!')
          return false
        }
        return true
      },
      //新增监控上传图片成功
      uploadFn(response) {
        if (response.code == 0){
          this.addOrEditData.iconImageUrl = response.data.url
        }
        else this.$message('图片上传失败')
      },
      //新增商标监控删除图片
      deleteImg() {
        this.addOrEditData.iconImageUrl = ""
        this.addOrEditData.sourctType = 0
      },

      //新增商标监控打开类别选择dialog
      open() { this.niceVisible = true },
      categoryType(value){
        if (value) this.monitorCategoryOrRenewalCategory = 0
        else this.monitorCategoryOrRenewalCategory = 1
      },

      //新增商标监控保存接口
      save() {
        localStorage.setItem('flag',1)
        if (this.addOrEditData.countrySet.length == 0){
          this.$message("请选择监控国家")
          return
        }
        if (this.addOrEdit == 1 && this.addOrEditData.countrySet.length > this.beforeEditCountryListNum){
          this.$message("您选择国家的个数已超过原监控国家的个数")
          return
        }

        if(this.addOrEditData.monitorType == 1){
          if (this.addOrEditData.niceSet == []){
            this.$message("请选择监控类别")
            return
          }

          if (this.addOrEditData.trademarkName == "" && this.addOrEditData.iconImageUrl == ""){
            this.$message("请输入商标名称或上传商标图片")
            return
          }
        }

        if(this.addOrEditData.monitorType == 2){
          if (!this.addOrEditData.applyPersonName){
            this.$message("请输入申请人名称")
            return
          }
        }

        if (this.containCN && this.addOrEditData.revokeThreeFlag != undefined && this.addOrEditData.revokeThreeFlag == true){
          if (this.addOrEditData.trademarkApplyNo == undefined || this.addOrEditData.trademarkApplyNo == ''){
            this.$message("请输入商标申请号")
            return
          }
          if (this.addOrEditData.trademarkOwnFlag == true ? false : this.addOrEditData.trademarkOwnFlag == false ? false : true){
            this.$message("请选择是否为商标专有人")
            return
          }
        }

        if (this.containCN && this.addOrEditData.renewalFlag != undefined && this.addOrEditData.renewalFlag == true){
          if (this.addOrEditData.trademarkApplyNo == undefined || this.addOrEditData.trademarkApplyNo == ''){
            this.$message("请输入商标申请号")
            return
          }
          if (this.addOrEditData.renewalNiceCode == undefined || this.addOrEditData.renewalNiceCode == ''){
            this.$message("请选择商标续展所需的商标类别")
            return
          }
        }

        if (!this.containCN){
          this.addOrEditData.revokeThreeFlag = undefined
          this.addOrEditData.renewalFlag = undefined
          this.addOrEditData.trademarkApplyNo = undefined
          this.addOrEditData.trademarkOwnFlag = undefined
          this.addOrEditData.renewalNiceCode = undefined
        }

        this.isDisabled = true
        //若存在商标名称，则创建文字图像并创建监控订单
        if (this.addOrEditData.trademarkName != "" && this.addOrEditData.trademarkName != undefined) {
          let jishu = this.getBLen(this.addOrEditData.trademarkName) * 0.6
          this.$axios.post("/trademark/trademark/generateUrl", {
            font: this.addOrEditData.trademarkName,
            fontpx: 1000 / jishu
          })
            .then(({data}) => {
              if (data.code == 0) {
                this.addOrEditData.wordImageUrl = data.data

                if (this.addOrEditData.id == ""){
                  localStorage.setItem('addOrEditData',JSON.stringify(this.addOrEditData))
                  this.createdOrderFn()
                }
                else {
                  this.updateOrderFn()
                }
              }
            })
            .catch(error => {
              this.isDisabled = false
            })
        }

        //若不存在商标名称，则直接创建监控订单
        else {
          if (this.addOrEditData.id == ""){
            localStorage.setItem('addOrEditData',JSON.stringify(this.addOrEditData))
            this.createdOrderFn()
          }
          else {
            this.updateOrderFn()
          }
        }
      },
      getBLen(str) {
        var len = 0
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94 || str.charCodeAt(i) == 87) {
            len += 2
          } else if ((str.charCodeAt(i) >= 65 && str.charCodeAt(i) <= 90)) {
            len += 1.3
          } else {
            len++
          }
        }
        if (len == 1) len = 1.8
        if (len == 1.3) len = 2
        return len
      },
      //创建监控订单
      createdOrderFn() {
        let doAddOrEditData = {}
        let sourceType = this.addOrEditData.iconImageUrl == '' ? 0 :this.addOrEditData.iconImageUrl.startsWith('/group') ? 1 : 0

        doAddOrEditData.countrySet = []

        if(this.addOrEditData.monitorType == 1){
          doAddOrEditData.trademarkName = this.addOrEditData.trademarkName
          doAddOrEditData.wordImageUrl = this.addOrEditData.wordImageUrl
          doAddOrEditData.iconImageUrl = this.addOrEditData.iconImageUrl
          doAddOrEditData.remarks = this.addOrEditData.remarks
          doAddOrEditData.niceSet = this.addOrEditData.niceSet
        }else {
          doAddOrEditData.applyPersonName = this.addOrEditData.applyPersonName
        }

        doAddOrEditData.monitorType = this.addOrEditData.monitorType
        doAddOrEditData.sourceType = sourceType
        doAddOrEditData.revokeThreeFlag = this.addOrEditData.revokeThreeFlag
        doAddOrEditData.renewalFlag = this.addOrEditData.renewalFlag
        doAddOrEditData.trademarkApplyNo = this.addOrEditData.trademarkApplyNo
        doAddOrEditData.trademarkOwnFlag = this.addOrEditData.trademarkOwnFlag
        doAddOrEditData.renewalNiceCode = this.addOrEditData.renewalNiceCode

        this.addOrEditData.countrySet.forEach(item=>{
          doAddOrEditData.countrySet.push(this.searchCountryCodeByName(item))
        })
        if(this.addOrEditData.monitorType == 1){
          this.$axios.post("/trademark/monitoring/createMonitoringImageOrWord", doAddOrEditData)
            .then(({data}) => {
              this.isDisabled = false
              if (data.code == 0) {
                this.isDisabled = false
                this.$message({
                  message: '商标监控添加成功',
                  type: 'success'
                })
                this.monitor = false
                this.getList()
              }
              else if (data.code == 900) {
                this.$confirm(`您增加的商标个数已超过商标监控的剩余个数，还需支付${data.data.paymentAmount}元`, '提示', {
                  confirmButtonText: '去购买',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(() => {
                    this.$router.push({
                      path: "/services",
                      query:{
                        flag: 1,
                        value: data.data.paymentCount,
                        prodServiceId: data.data.prodServiceId,
                        prices1: data.data.paymentAmount
                      }
                    })
                  })
                  .catch(() => {
                    this.$message({
                      type: 'info',
                      message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.remainTimes}个` : data.data.paymentSource == "monitor_renew" ? `本次启用失败。剩余个数还是${this.remainTimes}个` : `本次添加失败。剩余个数还是${this.remainTimes}个`
                    })
                  })
              }
              else if (data.code == 901){
                this.$confirm(data.data.paymentMessage, '提示', {
                  confirmButtonText: '去添加',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(()=>{
                    this.$router.push({
                      path: '/personalCenter/account'
                    })
                  })
                  .catch(()=>{
                    this.monitor = false
                    this.getList()
                  })
              }
              else if (data.code == 10001) { this.$message('参数校验失败') }
              else if (data.code == 10002) { this.$message('商标监控数量超过限制') }
              else if (data.code == 10003) { this.$message('没有商标监控权限') }
              else if (data.code == 10009) { this.$message('未知异常') }
              else { this.$message('添加失败') }
            })
            .catch(error => {
              this.isDisabled = false
              this.$message(error.message)
            })
        }else {
          this.$axios.post("/trademark/monitoring/createMonitoringCompetitor", doAddOrEditData)
            .then(({data}) => {
              this.isDisabled = false
              if (data.code == 0) {
                this.isDisabled = false
                this.$message({
                  message: '商标监控添加成功',
                  type: 'success'
                })
                this.monitor = false
                this.getList()
              }
              else if (data.code == 900) {
                this.$confirm(`您增加的商标个数已超过商标监控的剩余个数，还需支付${data.data.paymentAmount}元`, '提示', {
                  confirmButtonText: '去购买',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(() => {
                    this.$router.push({
                      path: "/services",
                      query:{
                        flag: 1,
                        value: data.data.paymentCount,
                        prodServiceId: data.data.prodServiceId,
                        prices1: data.data.paymentAmount
                      }
                    })
                  })
                  .catch(() => {
                    this.$message({
                      type: 'info',
                      message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.remainTimes}个` : data.data.paymentSource == "monitor_renew" ? `本次启用失败。剩余个数还是${this.remainTimes}个` : `本次添加失败。剩余个数还是${this.remainTimes}个`
                    })
                  })
              }
              else if (data.code == 901){
                this.$confirm(data.data.paymentMessage, '提示', {
                  confirmButtonText: '去添加',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(()=>{
                    this.$router.push({
                      path: '/personalCenter/account'
                    })
                  })
                  .catch(()=>{
                    this.monitor = false
                    this.getList()
                  })
              }
              else if (data.code == 10001) { this.$message('参数校验失败') }
              else if (data.code == 10002) { this.$message('商标监控数量超过限制') }
              else if (data.code == 10003) { this.$message('没有商标监控权限') }
              else if (data.code == 10009) { this.$message('未知异常') }
              else { this.$message('添加失败') }
            })
            .catch(error => {
              this.isDisabled = false
              this.$message(error.message)
            })
        }

      },
      //修改监控订单
      updateOrderFn(){
        let doAddOrEditData = {}

        doAddOrEditData.monitoringId = this.addOrEditData.id
        doAddOrEditData.countrySet = []
        if(this.addOrEditData.monitorType == 1){
          doAddOrEditData.trademarkName = this.addOrEditData.trademarkName
          doAddOrEditData.wordImageUrl = this.addOrEditData.wordImageUrl
          doAddOrEditData.iconImageUrl = this.addOrEditData.iconImageUrl
          doAddOrEditData.remarks = this.addOrEditData.remarks
          doAddOrEditData.niceSet = this.addOrEditData.niceSet
        }else {
          doAddOrEditData.applyPersonName = this.addOrEditData.applyPersonName
        }
        doAddOrEditData.monitorType = this.addOrEditData.monitorType
        doAddOrEditData.sourceType = this.addOrEditData.sourceType

        this.addOrEditData.countrySet.forEach(item=>{
          doAddOrEditData.countrySet.push(this.searchCountryCodeByName(item))
        })

        if(this.addOrEditData.monitorType == 1){
          this.$axios.post("/trademark/monitoring/updateMonitoringImageOrWord", doAddOrEditData)
            .then(({data}) => {
              this.isDisabled = false
              if (data.code == 0) {
                this.isDisabled = false
                this.$message({
                  message: '商标监控修改成功',
                  type: 'success'
                })
                this.monitor = false
                this.getList()
              }
              else if (data.code == 900) {
                this.$confirm(`您增加的商标个数已超过商标监控的剩余个数，还需支付${data.data.paymentAmount}元`, '提示', {
                  confirmButtonText: '去购买',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(() => {
                    this.$router.push({
                      path: "/services",
                      query:{
                        flag: 1,
                        value: data.data.paymentCount,
                        prodServiceId: data.data.prodServiceId,
                        prices1: data.data.paymentAmount
                      }
                    })
                  })
                  .catch(() => {
                    this.$message({
                      type: 'info',
                      message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.remainTimes}个` : data.data.paymentSource == "monitor_renew" ? `本次启用失败。剩余个数还是${this.remainTimes}个` : `本次添加失败。剩余个数还是${this.remainTimes}个`
                    })
                  })
              }
              else if (data.code == 901){
                this.$confirm(data.data.paymentMessage, '提示', {
                  confirmButtonText: '去添加',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(()=>{
                    this.$router.push({
                      path: '/personalCenter/account'
                    })
                  })
                  .catch(()=>{
                    this.monitor = false
                    this.getList()
                  })
              }
              else if (data.code == 10001) { this.$message('参数校验失败') }
              else if (data.code == 10002) { this.$message('商标监控数量超过限制') }
              else if (data.code == 10003) { this.$message('没有商标监控权限') }
              else if (data.code == 10009) { this.$message('未知异常') }
              else { this.$message('添加失败') }
            })
            .catch(error => {
              this.isDisabled = false
              this.$message(error.message)
            })
        }else {
          this.$axios.post("/trademark/monitoring/updateMonitoringCompetitor", doAddOrEditData)
            .then(({data}) => {
              this.isDisabled = false
              if (data.code == 0) {
                this.isDisabled = false
                this.$message({
                  message: '商标监控修改成功',
                  type: 'success'
                })
                this.monitor = false
                this.getList()
              }
              else if (data.code == 900) {
                this.$confirm(`您增加的商标个数已超过商标监控的剩余个数，还需支付${data.data.paymentAmount}元`, '提示', {
                  confirmButtonText: '去购买',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(() => {
                    this.$router.push({
                      path: "/services",
                      query:{
                        flag: 1,
                        value: data.data.paymentCount,
                        prodServiceId: data.data.prodServiceId,
                        prices1: data.data.paymentAmount
                      }
                    })
                  })
                  .catch(() => {
                    this.$message({
                      type: 'info',
                      message: data.data.paymentSource == "monitor_renew" ? `本次续展失败。剩余个数还是${this.remainTimes}个` : data.data.paymentSource == "monitor_renew" ? `本次启用失败。剩余个数还是${this.remainTimes}个` : `本次添加失败。剩余个数还是${this.remainTimes}个`
                    })
                  })
              }
              else if (data.code == 901){
                this.$confirm(data.data.paymentMessage, '提示', {
                  confirmButtonText: '去添加',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then(()=>{
                    this.$router.push({
                      path: '/personalCenter/account'
                    })
                  })
                  .catch(()=>{
                    this.monitor = false
                    this.getList()
                  })
              }
              else if (data.code == 10001) { this.$message('参数校验失败') }
              else if (data.code == 10002) { this.$message('商标监控数量超过限制') }
              else if (data.code == 10003) { this.$message('没有商标监控权限') }
              else if (data.code == 10009) { this.$message('未知异常') }
              else { this.$message('添加失败') }
            })
            .catch(error => {
              this.isDisabled = false
              this.$message(error.data.msg)
            })
        }
      },
      //根据国家名称查询国家Code
      searchCountryCodeByName(countryName){
        let s = ""
        this.allCountry.map(item=>{
          if (item.city == countryName){
            s = item.code
          }
        })
        return s
      },

      close() {
        this.niceVisible = false
        this.monitor = true
      },
      classifyType(data) {
        if (this.monitorCategoryOrRenewalCategory == 0){
          this.addOrEditData.niceSet = []
          data.forEach(item=>{
            this.addOrEditData.niceSet.push(item.no)
          })
        }
        else {
          if (data.length > 1){
            this.$message("此处仅可以选择一个类别")
            return
          }
          else {
            this.addOrEditData.renewalNiceCode = data[0].no
          }
        }
      },

      // 登录弹层隐藏
      loginDialog(data) { this.dialogVisibles = data },
      dialogCloseBool(data) { this.dialogClose = data },
      closeEvent(done) {
        this.dialogClose = true
        done()
      },
    },

    computed: {
      //所有国家
      allCountry(){ return this.$t(`PQ.cityOptions`).concat(this.$t(`PQ.city`), this.$t(`PQ.cityOptionsd`), this.$t(`PQ.cityOptionsds`), this.$t(`PQ.cityOpts`), this.$t(`PQ.cityds`), this.$t(`PQ.propertyOrganization`))},
    }
  }
</script>

<style lang="less">
  .trademarkMonitoring {
    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53%, .1);
      border-radius: 10px
    }

    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-track {
      background: hsla(0, 0%, 53%, .1)
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
      background-color: #dfecfb;
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.col--fixed {
      cursor: no-drop;
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
    }

    .vxe-table--header {
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;
    }


    .vxe_grid_header {
      border-radius: 15px 15px 0px 0px !important;
      overflow: hidden !important;
      background: #E2EFFF !important;
    }

    .row--current, .row--hover {
      background: #ffffff !important;
      -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      -webkit-transform: translate3d(0, 0px, -2px);
      transform: translate3d(0, 1px, -2px);
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
    }

    .col-orange div {
      width: 118px !important;
      display: flex !important;
      height: 48px !important;
      padding: 2px 0 !important;
      justify-content: center !important;
    }
  }
</style>

<style lang="less">
  .el-select-dropdown__item {
    height: auto !important;
  }

  .el-tree {
    display: flex;
    flex-direction: column;
  }

  section.search-option {
    position: relative;
    min-height: 100px;
    background-color: #ffffff;
    border-radius: 3px;
    box-sizing: border-box;
    border: solid 1px #e4e4e4;
    margin-bottom: 20px;
    padding-top: 47px;
    padding-left: 50px;
    height: 135px;
    .zhihui {
      top: -80px;
      right: -55px;
      position: absolute;
      width: 265px;
      height: auto;
    }
    .search_title {
      font-size: 14px;
      color: #474747;
      margin-right: 14px;
    }

    div {
      margin-bottom: 10px;
      margin-right: 5px;
    }

    .el-select-long-officialt {
      width: 180px;
      height: 34px;
      border-radius: 3px;
      background: #ffffff;

      .el-input {
        input.el-input__inner {
          border: 1px solid #cccccc;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .one, .el-input {
      width: 180px;

      input {
        height: 34px !important;
        border-color: #ccc;
        color: inherit;
        padding: 0 4px;
      }
    }

    ._one /deep/ .el-input__inner {
      height: 34px;
    }

    .el-date-editor-long-officialt {
      width: 180px;
      height: 34px;
      border-radius: 3px;
      background: #ffffff;
      line-height: 32px;

      input.el-input__inner {
        border: 1px solid #cccccc;
        text-indent: 42px;
      }
    }
  }

  .trademarkMonitoring {
    .danTitle {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      color: #999999;
      font-weight: 600;
      position: relative;
      margin-left: 17px;

      &:before {
        position: absolute;
        content: '';
        width: 3px;
        height: 16px;
        top: 17px;
        background: #1a3dbf;
      }

      span {
        margin: 0 9px;

        &:nth-of-type(1) {
          margin-left: 18px;
        }

        &:nth-of-type(3) {
          color: #506bce;
        }
      }

      span:hover {
        cursor: pointer;
        color: #506bce;
      }
    }

    padding: 0 1%;
    box-sizing: border-box;

    .monitoring_top {
      height: 131px;
      border: 1px solid #e4e4e4;
      background: #fff;
      margin: 15px auto;

      .condition {
        padding: 45px 0 0 45px;

        .el-input__inner {
          height: 36px;
        }

        .el-input {
          width: 220px;
          margin-right: 20px;
          padding: 0px;
          margin-left: 10px;
        }
      }
    }

    .monitoring_two {
      width: 100%;
      margin: auto;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 9px;
      justify-content: space-between;

      .totalMonitoring {
        background: #f4f7ff;
        font-size: 14px;
        color: #383838;
        width: 354px;
        height: 44px;
        border-radius: 20px;
        margin-right: 32px;
        display: flex;
        align-items: center;
        padding: 0 37px 0 33px;
        justify-content: space-between;
        box-sizing: border-box;

        i {
          font-size: 16px;
        }

        .active1 {
          color: #3057e3;
        }

        .active2 {
          color: #ff8d1a;
        }
      }

      .jg {
        width: 14px;
        cursor: pointer;
        position: absolute;
        margin-left: 0px;
        margin-right: 14px;
      }

      .recommend-tips {
        vertical-align: bottom;
        width: 425px;
        padding: 7px 12px;
        line-height: 17px;
        background: #fbedec;
        border: 1px solid #f6d4d4;
        font-size: 12px;
        text-align: left;

        &:before {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          border-bottom: 1px solid #f6d4d4;
          border-left: 1px solid #f6d4d4;
          top: 50%;
          left: -7px;
          transform: translateY(-50%) rotate(45deg);
          background: #fbedec;
        }
      }

      .addMsg {
        position: absolute;
        left: 110px;
        top: -18px;
      }

      .add {
        width: 152px;
        height: 32px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        text-align: center;
        line-height: 30px;
        color: #2c3e50;
        cursor: pointer;
        margin-top: 30px;
      }

      .jum {
        width: 120px;
        margin-left: 15px;
      }

      .add:hover {
        background: #193dbe;
        color: #fff;
        border: 1px solid #193dbe;
      }

      .batchImport {
        float: right;
        margin-top: 35px;

        span {
          margin-right: 15px;
          vertical-align: middle;

          img {
            vertical-align: middle;
          }

          .upload-demo {
            .el-upload-dragger {
              width: 100px;
              height: auto;
              background: none;
              border: none;
              margin-top: 3px;
            }

            .el-upload-list {
              display: none;
            }
          }
        }

        .icondaoru-tianchong {
          font-weight: bold;
          vertical-align: middle;
          color: #de5455;
          margin-right: 3px;
          font-size: 18px;
        }
      }
    }

    .monitoring_table {
      padding: 10px;
      background: white;
      border: 1px solid #ddddde;
    }

    .afterBreak{
      position: relative;
    }
    .afterBreak:after{
      position: absolute;
      top: 5px;
      right: -12px;
      width: 1px;
      height: 12px;
      content: "";
      background-color: #CAD3DC;
    }

    .seeResults:hover {
      text-decoration: underline;
    }

    .primary {
      background: #d01027;
      border: #d01027;
    }

    .primary:hover {
      background: #d73e3f;
    }

    .can:hover {
      color: white;
      background-color: #d01027;
      border: 1px solid #d01027;
    }

    .fyBlock {
      padding-bottom: 1%;
      padding-top: 2%;
      text-align: center;
    }

    .monitor {

      .prompt {
        height: 58px;
        line-height: 58px;
        border-bottom: 1px solid #e4e4e4;
        margin: 0 32px;
        text-align: center;

        .text {
          font-size: 14px;
          color: #222222;
          vertical-align: middle;
          margin-left: 10px;
        }
      }

      .mainBody {
        width: 495px;
        margin: 0px auto 0px;

        .check {
          margin-bottom: 20px;

          .requiredStar {
            color: #d01027;
            font-weight: bold;
            font-size: 16px;
          }

          .title:nth-child(1) {
            width: 105px;
            font-size: 14px;
            color: #222222;
            text-align: left;
            vertical-align: middle;
          }

          .title {
            display: inline-block;
          }

          .right {
            width: 380px;
            text-align: left;
            vertical-align: middle;
            position: relative;
            display: inline-block;

            .upload-drag {
              height: 82px !important;

              &.uploadDisabled {
                position: relative;

                .el-upload {
                  .el-upload-dragger {
                    .el-upload-disabled-shade {
                      display: block;
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      top: 0;
                      right: 0;
                      cursor: not-allowed;
                    }
                  }
                }
              }
            }

            .el-upload-dragger {
              border: 1px solid #e4e4e4;
              height: 82px;
              width: 380px;
              cursor: pointer;
            }

            .upload-img {
              height: 96%;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }

            p.delete {
              position: absolute;
              z-index: 5;
              right: 10px;
            }

            p {
              margin-top: 12px;
              font-size: 12px;
              letter-spacing: 1px;
              color: #999999;

              &.delete:hover {
                color: #0b6ce8;
              }
            }

            .leftText {
              height: 36px;
              border-color: #e4e4e4;
            }

            .el-input-long-officialt {
              width: 380px;
              background-color: #fff;

              &.active {
                width: 360px;
              }
            }

            .rightText {
              float: right;
              border-left: 1px solid #ccc;
              width: 40px;
              text-align: center;
            }
            .backgroundColorGrey{
              .el-input__inner{
                background-color: rgb(234,235,241);
              }
            }

            .el-select-long-officialt {
              width: 380px;
              background-color: #fff;

              .el-input__suffix-inner {
                margin-top: 9px;
              }
            }

            .result {
              margin: 10px 0 0;

              .resultImg {
                width: 82px;
                height: 82px;
                border: 1px solid #e4e4e4;
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                float: left;
              }

              .resultText {
                float: left;
                margin-left: 16px;

                span {
                  color: #222;
                  font-size: 14px;
                }
              }
            }
          }

          .niceCode {
            width: 380px;
            border: 1px solid #e4e4e4;
            height: 38px;
            border-radius: 3px;

            .leftText {
              border: transparent;
              width: 310px;

              input.el-input__inner {
                border: none !important;
              }
            }

            .rightText {
              width: 60px;
              height: 25px;
              margin-top: 9px;
            }
          }

          .el-select {
            .el-select__tags {
              .el-select__input {
                border: none !important;
              }
            }
          }
        }
      }

      .dialog-footer {
        float: right;
        margin-right: 113px;

        .submit {
          width: 90px;
          height: 36px;
          display: inline-block;
          background-color: #d01027;
          color: #fff;
          line-height: 36px;
          text-align: center;
          font-size: 14px;
          margin-top: 22px;
          margin-left: 32px;
          cursor: pointer;
          border-radius: 3px;
        }

        .submit:hover {
          background-color: #d73e3f;
        }
      }
    }
  }

  .long-dialog{
    .monitorDialogTitle{
      font-size: 16px;
      position: relative;
      display: inline-block;
      padding: 0 0 10px;
      margin: 0 10px;
      letter-spacing: 1px;
      cursor: pointer;
    }
    .monitorDialogTitle:first-of-type:before{
      position: absolute;
      content: "";
      height: 12px;
      width: 1px;
      background-color: #D5D5E9;
      top:5px;
      right: -10px;
    }
    .monitorActive{
      color: rgb(7,44,182)!important;
      font-weight: bolder;
    }
    .monitorActive:after{
      position: absolute;
      bottom: 0;
      left: calc( (100% - 30px) / 2 );
      content: '';
      width: 26px;
      height: 3px;
      border-radius: 2px;
      background-color: rgb(6,43,183);
    }
    .el-dialog__body{
      padding: 0 20px 40px;
    }
    .monitor{
      padding: 40px 30px 20px;
      background-color: rgb(242,241,246);
    }
  }

  .long-dialog-old.el-dialog__wrapper {
    text-align: center;

    .el-dialog {
      width: auto;
      display: inline-block;
    }

    .el-dialog__header {
      padding: 0;
      height: 0px;
      display: inline-block;

      .el-icon-close {
        top: 0;
        right: 0;
        font-size: 20px;
      }
    }

    .el-dialog__body {
      padding: 0;
      display: inline-block;
    }

    .long-inner {
      width: 740px;
      border-radius: 30px;
      text-align: left;

      &.long-inner-viena {
        width: 950px;
      }

      .top {
        height: 53px;
        line-height: 53px;
        font-size: 14px;
        letter-spacing: 1px;
        color: #666666;
        padding: 0px 50px;
        border-bottom: 1px solid #cecece;
        background-color: #efefef;
        border-radius: 3px;

        i {
          color: #e96364;
        }

        span {
          margin-left: 12px;
        }
      }

      /*尼斯分类*/
      .middle.nice-style {
        height: 530px;
        overflow: auto;

        .first-ul {
          font-size: 13px;

          .first-li {
            padding: 0;
            text-indent: 50px;

            .first-p {
              cursor: pointer;
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              letter-spacing: 0px;
              color: #333333;

              &:hover {
                background: #f7f7f7;
              }

              .el-checkbox {
                text-indent: 0;
                //复选框样式见long.less
              }
            }

            .second-ul {
              .second-li {
                padding: 0;
                text-indent: 50+18px;

                &:nth-last-of-type(1) {
                  padding-bottom: 0;
                }

                .second-p {
                  cursor: pointer;
                  height: 34px;
                  line-height: 34px;

                  &:hover {
                    background: #f7f7f7;
                  }

                  .el-checkbox {
                    text-indent: 0;
                    //复选框样式见long.less
                  }
                }
              }
            }
          }
        }
      }

      .bottom {
        height: 94px;
        padding: 30px 54px;
        text-align: right;

        .el-button {
          height: 30px;
          width: 100px;
        }

        .viennaConfirm {
        }

        .viennaClose {
          margin-left: 22px;
        }
      }
    }
  }
</style>
